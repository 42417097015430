import {defineStore} from 'pinia'
import {NetworkService} from "~/utils/NetworkService";
import type {InviteGuestRb} from "~/models/request/inviteGuestRb";
import type {GetEventStudentInvoicesRb} from "~/models/request/getEventStudentInvoicesRb";
import type {Ticket} from "~/models/ticket";
import type {AcceptRejectInvitationRb} from "~/models/request/acceptRejectInvitationRb";
import type {ResendRsvpInvitationRb} from "~/models/request/resendRsvpInvitationRb";
import type {ReminderRSVPInvitationRb} from "~/models/request/ReminderRSVPInvitationRb";
import type {AppreciationRSVPInvitationRb} from "~/models/request/AppreciationRSVPInvitationRb";
import type {sendTicketReminderRb} from "~/models/request/sendTicketReminderRb";

export const eventStore = defineStore({
    id: 'event-store',
    persist: true,
    state: () => {
        return {
            addedParticipantsOnEventTicketsPage: <any[]>[],
            allTicketsOfCurrentEvent:<Ticket[]>[],
        }
    },
    actions: {
        clearEventStore: function () {
            this.clearAllTicketsOfCurrentEvent();
            this.clearAddedParticipantsOnEventTicketsPage();
        },
        clearAddedParticipantsOnEventTicketsPage: function (){
            this.addedParticipantsOnEventTicketsPage = [];
        },
        clearAllTicketsOfCurrentEvent: function () {
            this.allTicketsOfCurrentEvent = [];
        },
        addIntoAddedParticipantsOnEventTicketsPage: function (studentInfo:any) {
            if(studentInfo.id){
                const foundStudent = this.addedParticipantsOnEventTicketsPage.find((item)=>item.id==studentInfo.id);
                if(!foundStudent){
                    this.addedParticipantsOnEventTicketsPage.push(studentInfo);
                }
            }
        },
        inviteGuestToTheEvent:  async function (inviteGuestRb:InviteGuestRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/inviteParticipantToEvent';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, inviteGuestRb, null, "inviteGuestToTheEvent" + new Date().getMilliseconds() + "_")
            return Result;
        },
        acceptRejectInvitationServer:  async function (acceptRejectInvitationRb:AcceptRejectInvitationRb) {
            const config = useRuntimeConfig()
            let url = '/api/club/accept-invitation';
            const ns = new NetworkService();
            let {IsResult} = await ns.post$fetch(url, acceptRejectInvitationRb, null, "acceptRejectInvitationServer" + new Date().getMilliseconds() + "_")
            return IsResult;
        },
        getAllEventTickets:  async function (getEventStudentInvoicesRb:GetEventStudentInvoicesRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/getEventStudentInvoiceByClubId';
            const ns = new NetworkService();
            let {Result} = await ns.post$fetch(url, getEventStudentInvoicesRb, null, "getAllEventTickets" + new Date().getMilliseconds() + "_")
            return Result;
        },
        resendRsvpInvitations:  async function (resendRsvpInvitationRb:ResendRsvpInvitationRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/resendParticipantInvitation';
            const ns = new NetworkService();
            let {IsResult} = await ns.post$fetch(url, resendRsvpInvitationRb, null, "resendRsvpInvitations" + new Date().getMilliseconds() + "_")
            return IsResult;
        },
        sendTicketReminder :  async function (sendTicketReminderRb:sendTicketReminderRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/sendTicketReminder ';
            const ns = new NetworkService();
            let {IsResult} = await ns.post$fetch(url, sendTicketReminderRb, null, "sendTicketReminder" + new Date().getMilliseconds() + "_")
            return IsResult;
        },
        reminderRsvpInvitations:  async function (reminderRsvpInvitationRb:ReminderRSVPInvitationRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/sendEventReminder';
            const ns = new NetworkService();
            let {IsResult} = await ns.post$fetch(url, reminderRsvpInvitationRb, null, "resendRsvpInvitations" + new Date().getMilliseconds() + "_")
            return IsResult;
        },

        sendEmailForRSVPAppreciationNote:  async function (reminderRsvpInvitationRb:AppreciationRSVPInvitationRb) {
            const config = useRuntimeConfig()
            let url = config.public.BASE_URL_SOPS + '/api/fee/sendEmailForRSVPAppreciationNote';
            const ns = new NetworkService();
            let {IsResult} = await ns.post$fetch(url, reminderRsvpInvitationRb, null, "sendEmailForRSVPAppreciationNote" + new Date().getMilliseconds() + "_")
            return IsResult;
        },
        loadAndSetAllTicketsOfCurrentEvent: async function (getEventStudentInvoicesRb:GetEventStudentInvoicesRb){
            this.clearAllTicketsOfCurrentEvent()
            const response = await this.getAllEventTickets(getEventStudentInvoicesRb);
            if(response){
                for(const tmpTicket of response??[]){
                    this.allTicketsOfCurrentEvent.push(tmpTicket as Ticket);
                }
            }
        }
    },
    getters: {
        getAddedParticipantsOnEventTicketsPage: state => state.addedParticipantsOnEventTicketsPage,
        getPrimaryTicketsOfCurrentEvent: state => state.allTicketsOfCurrentEvent.sort((a,b)=>{
            return a.id>b.id?-1:1;
        }).filter((ticket)=>{
            return ticket.IsPrimary
        }),
        getRelatedParticipantsOfAParticipantFromCurrentEvent: state => function (invoiceId:number) {
            const guests = [];
            const allHead = [];
            const primaryTicketOfTheInvoice = state.allTicketsOfCurrentEvent.find((item)=>item.invoice_id==invoiceId && item.IsPrimary==true);
            if(primaryTicketOfTheInvoice?.Accepted??false){
                for(const tmpTicket of state.allTicketsOfCurrentEvent){
                    if(tmpTicket.invoice_id==invoiceId){
                        allHead.push(tmpTicket)
                        if(!tmpTicket.IsPrimary){
                            guests.push(tmpTicket)
                        }
                    }
                }
            }
            return {
                guests,
                allHead
            };
        }
    },
})
